import { ISession } from "../types";
import { DELETE_SESSION, SET_SESSION } from "./actionTypes";

export const setSession = (session : ISession) => ({
    type: SET_SESSION,
    payload: session
})

export const deleteSession = () => ({
    type: DELETE_SESSION,
    payload: null
})