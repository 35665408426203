import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Theme,
} from '@material-ui/core';
import withStyles, { Styles } from '@material-ui/core/styles/withStyles';
import React from 'react';

const styles: Styles<Theme, {}> = (theme) => ({
	root: {
		width: 300,
	},
});

interface DialogProps {
	classes: Record<string, string>;
	title: string;
	open: boolean;
	onClose: (e: any) => void;
	positiveAction?: string;
	negativeAction?: string;
	onPositiveAction?: (e: any) => void;
	onNegativeAction?: (e: any) => void;
	autoWidth?: boolean;
}

interface DialogState {}

class FullDialog extends React.Component<DialogProps, DialogState> {
	render() {
		const {
			children,
			classes,
			title,
			open,
			onClose,
			positiveAction,
			negativeAction,
			onPositiveAction,
			onNegativeAction,
			autoWidth,
		} = this.props;

		return (
			<Dialog
				open={open}
				onClose={onClose}
				PaperProps={{ className: autoWidth ? '' : classes.root }}
			>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>{children}</DialogContent>
				<DialogActions>
					{negativeAction && (
						<Button onClick={onNegativeAction}>
							{negativeAction}
						</Button>
					)}
					{positiveAction && (
						<Button color='primary' onClick={onPositiveAction}>
							{positiveAction}
						</Button>
					)}
				</DialogActions>
			</Dialog>
		);
	}
}

export default withStyles(styles)(FullDialog);
