import {
	Dialog,
	LinearProgress,
	Theme
} from '@material-ui/core';
import withStyles, { Styles } from '@material-ui/core/styles/withStyles';
import React from 'react';

const styles: Styles<Theme, {}> = (theme) => ({
	paper: {
		width: 200,
		backgroundColor: theme.palette.primary.main,
	},
    bar: {
        backgroundColor: '#ffffff'
    },
});

interface LoadingDialogProps {
	classes: Record<string, string>;
	open: boolean;
}

interface LoadingDialogState {}

class LoadingDialog extends React.Component<
	LoadingDialogProps,
	LoadingDialogState
> {
	render() {
		const { open, classes } = this.props;

		return (
			<Dialog
				open={open}
				PaperProps={{
					className: classes.paper,
				}}
			>
				<div style={{ width: '100%' }}>
					<LinearProgress
						style={{ margin: '1rem' }}
						classes={{
                            bar: classes.bar,
						}}
					/>
				</div>
			</Dialog>
		);
	}
}

export default withStyles(styles)(LoadingDialog);
