import {
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Theme,
} from '@material-ui/core';
import withStyles, { Styles } from '@material-ui/core/styles/withStyles';
import {
	VisibilityRounded as EyeIcon,
	VisibilityOffRounded as EyeOffIcon,
} from '@material-ui/icons';
import { OptionsObject } from 'notistack';
import React from 'react';
import fetchApi from '../fetch';

import Dialog from './dialog';

const styles: Styles<Theme, {}> = (theme) => ({
	field: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
});

export interface IUser {
	_id: string;
	name: string;
	username: string;
	password: string;
	type: number;
}

interface UserDialogProps {
	classes: Record<string, string>;
	open: boolean;
	onClose: () => void;
	onSubmit: () => void;
	value: IUser;
	onToggleLoading: (e: boolean) => void;
	enqueueSnackbar: (
		message: React.ReactNode,
		options: OptionsObject | undefined
	) => React.ReactText;
}

interface UserDialogState {
	value: IUser;
	showPassword: boolean;
}

class UserDialog extends React.Component<UserDialogProps, UserDialogState> {
	constructor(props: UserDialogProps) {
		super(props);
		this.state = {
			value: {
				_id: '',
				username: '',
				name: '',
				password: '',
				type: 1,
			},
			showPassword: false,
		};
	}

	componentDidMount() {
		const { value: propsValue } = this.props;
		this.setState({ value: propsValue });
	}

	componentDidUpdate(oldProps: UserDialogProps) {
		const { value: propValue } = this.props;
		const { value } = this.state;
		if (
			value._id !== propValue._id &&
			propValue._id !== oldProps.value._id
		) {
			console.log('cambio el valor');
			this.setState({ value: propValue });
		}
	}

	handleShowPassword() {
		return (e: any) =>
			this.setState({ showPassword: !this.state.showPassword });
	}

	handleValue(prop: keyof IUser) {
		const value = this.state.value;
		return (e: any) =>
			this.setState({
				value: {
					...value,
					[prop]: (e.target as HTMLInputElement).value,
				},
			});
	}

	submit() {
		const { onSubmit, onToggleLoading } = this.props;
		return async (e: any) => {
			onToggleLoading(true);
			const usuario = this.state.value;
			const { enqueueSnackbar } = this.props;
			let url = 'user/register';

			if (usuario._id !== '') {
				url = 'user/edit/' + usuario._id;
			}

			const r = await fetchApi(url, { ...usuario });

			if (r.ok) {
				enqueueSnackbar(
					`Usuario ${
						usuario._id === '' ? 'creado' : 'actualizado'
					} con exito`,
					{
						variant: 'success',
					}
				);
				onSubmit();
			} else {
				console.log(r.data);
				enqueueSnackbar(r.data, { variant: 'error' });
			}
		};
	}

	get validUser() {
		const { name, password, username } = this.state.value;
		return name !== '' && password !== '' && username !== '';
	}

	render() {
		const { open, onClose, value: propValue, classes } = this.props;
		const { value, showPassword } = this.state;

		return (
			<Dialog
				title={
					propValue._id !== '' ? 'Editar usuario' : 'Nuevo usuario'
				}
				open={open}
				onClose={onClose}
				positiveAction='crear'
				negativeAction='cancelar'
				onPositiveAction={this.submit()}
				onNegativeAction={onClose}
			>
				<Grid container>
					<Grid item xs={12} className={classes.field}>
						<TextField
							fullWidth
							label='Nombre'
							value={value.name}
							onChange={this.handleValue('name')}
						/>
					</Grid>
					<Grid item xs={12} className={classes.field}>
						<TextField
							fullWidth
							label='Usuario'
							value={value.username}
							onChange={this.handleValue('username')}
						/>
					</Grid>
					<Grid item xs={12} className={classes.field}>
						<TextField
							fullWidth
							label='Contraseña'
							value={value.password}
							type={showPassword ? 'text' : 'password'}
							onChange={this.handleValue('password')}
							InputProps={{
								endAdornment: (
									<IconButton
										onClick={this.handleShowPassword()}
									>
										{showPassword ? (
											<EyeOffIcon />
										) : (
											<EyeIcon />
										)}
									</IconButton>
								),
							}}
						/>
					</Grid>
					<Grid item xs={12} className={classes.field}>
						<FormControl fullWidth>
							<InputLabel id='nivel_user_dialog'>
								Nivel
							</InputLabel>
							<Select
								labelId='nivel_user_dialog'
								fullWidth
								label='Nivel'
								value={value.type}
								onChange={this.handleValue('type')}
							>
								<MenuItem value={1}>Usuario</MenuItem>
								<MenuItem value={99}>Administrador</MenuItem>
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</Dialog>
		);
	}
}

export default withStyles(styles)(UserDialog);
