import { ISession } from '../../types';
import { DELETE_SESSION, SET_SESSION } from '../actionTypes';

interface IAction {
	type: string;
	payload: ISession | null;
}

const initialState: ISession | null = null;

export default function session(state = initialState, action: IAction) {
	switch (action.type) {
		case SET_SESSION:
			return action.payload as ISession;

		case DELETE_SESSION:
			return null;
		default:
			return state;
	}
}
