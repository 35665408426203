import { Menu, MenuItem } from '@material-ui/core';
import React from 'react';

export interface IMenuItem {
	label: string;
	value: string | number;
}

interface ContextMenuProps {
	items: IMenuItem[];
	coords: {
		x: number;
		y: number;
	} | null;
	onClose: (e: any) => void;
	onItemClick?: (e: any, item: IMenuItem) => void;
}

interface ContextMenuState {}

class ContextMenu extends React.Component<ContextMenuProps, ContextMenuState> {
	render() {
		const { items, coords, onClose, onItemClick } = this.props;

		return (
			<Menu
				keepMounted
				open={Boolean(coords)}
				onClose={onClose}
				anchorReference='anchorPosition'
				anchorPosition={
					coords ? { top: coords.y, left: coords.x } : undefined
				}
			>
				{items.map((i) => (
					<MenuItem
						key={i.value}
						onClick={(e) =>
							onItemClick ? onItemClick(e, i) : null
                        }
					>
						{i.label}
					</MenuItem>
				))}
			</Menu>
		);
	}
}

export default ContextMenu;
