import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { setSession } from '../redux/actions';
import { getCookieSession } from '../cookies';
import { RootState } from '../redux/reducers';
import { getSession } from '../redux/selectors';
import { ISession } from '../types';

class Index extends React.Component<
	RouteComponentProps & {
		setSession: typeof setSession;
		session: ISession | null;
	},
	{},
	any
> {
	componentDidMount() {
		const session = getCookieSession();
		if (session && !this.props.session) {
			this.props.setSession(session);
		} else if (this.props.session) {
			this.props.history.replace(`/${this.props.session.type}`);
		} else {
			this.props.history.replace('/login');
		}
	}

	componentDidUpdate() {
		const { history, session } = this.props;
		if (session) {
			history.replace(`/${session.type}`);
		}
	}

	render() {
		return <div>index</div>;
	}
}

export default withRouter(
	connect(
		(store: RootState, ownProps) => {
			return { session: getSession(store), ...ownProps };
		},
		{ setSession }
	)(Index)
);
