import { Component } from 'react';

import { Grid, Theme } from '@material-ui/core';
import withStyles, { Styles } from '@material-ui/core/styles/withStyles';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import Chart from 'react-apexcharts';

import {
	amber,
	blue,
	cyan,
	deepOrange,
	deepPurple,
	green,
	indigo,
	lightBlue,
	lime,
	pink,
	purple,
	red,
	teal
} from '@material-ui/core/colors';
import MultipleSelect, { SelectItem } from '../multipleSelect';

import fetchApi from '../../fetch';

const styles: Styles<Theme, {}> = (theme) => ({
	root: {
		padding: theme.spacing(2),
	},
});

interface DashboardProps extends WithSnackbarProps {
	classes: Record<string, string>;
}

interface DashboardState {
	letterSelect: string[];
	letters: SelectItem[];
	optionsMainChart: any;
	dataMainChart: any;
	optionsSecondaryChart1: any;
	dataSecondaryChart1: any;
	optionsSecondaryChart2: any;
	dataSecondaryChart2: any;
}

const defaultState: DashboardState = {
	letterSelect: [],
	letters: [],
	optionsMainChart: {
		plotOptions: {
			bar: {
				horizontal: false,
				endingShape: 'rounded',
				columnWidth: '40%',
			},
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			show: true,
			width: 2,
			colors: ['transparent'],
		},
		xaxis: {
			categories: ['Cargando...'],
		},
		yaxis: {
			title: {
				text: 'Cantidad',
			},
		},
		fill: {
			opacity: 1,
		},
		colors: [deepPurple.A200, blue.A200, lightBlue.A200],
		tooltip: {
			y: {
				formatter: function (val: any) {
					return val + ' registros';
				},
			},
		},
		title: {
			text:
				'Conteo de enlaces abiertos al menos una vez para pdf, imagen y el total de registros por carta',
		},
	},
	dataMainChart: [
		{
			name: 'Total',
			data: [1, 1, 1, 1],
		},
		{
			name: 'Abierto en Imagen',
			data: [1, 1, 1, 1],
		},
		{
			name: 'Abierto en PDF',
			data: [1, 1, 1, 1],
		},
	],
	optionsSecondaryChart1: {
		chart: {
			type: 'donut',
		},
		responsive: [
			{
				breakpoint: 480,
				options: {
					chart: {
						width: 200,
					},
					legend: {
						position: 'bottom',
					},
				},
			},
		],
		colors: [blue.A400, deepPurple.A400],
		labels: ['Abiertos al menos una vez', 'Sin abrir'],
		title: {
			text:
				'Comparacion entre enlaces abiertos al menos una vez y sin abrir',
		},
	},
	dataSecondaryChart1: [1, 1],
	optionsSecondaryChart2: {
		chart: {
			type: 'donut',
		},
		responsive: [
			{
				breakpoint: 480,
				options: {
					chart: {
						width: 200,
					},
					legend: {
						position: 'bottom',
					},
				},
			},
		],
		colors: [
			indigo[400],
			deepPurple[400],
			purple[400],
			pink[400],
			red[400],
			deepOrange[400],
			amber[400],
			lime[400],
			green[400],
			teal[400],
			cyan[400],
			lightBlue[400],
			blue[400],
		],
		labels: ['Cargando'],
		title: {
			text: 'Distribucion de enlaces abiertos al menos una vez',
		},
	},
	dataSecondaryChart2: [1],
};

const generarDataMainChart = (total: any, image: any, pdf: any) => {
	return [
		{
			name: 'Total',
			data: total,
		},
		{
			name: 'Abierto en Imagen',
			data: image,
		},
		{
			name: 'Abierto en PDF',
			data: pdf,
		},
	];
};

class Dashboard extends Component<DashboardProps, DashboardState> {
	state = defaultState;
	interval = -1;

	componentDidMount() {
		this.cargarInformacion();
		this.cargarCartas()
		setInterval(() => {
			this.cargarInformacion()
			this.cargarCartas()
		}, 10000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	componentDidUpdate(prevProps: DashboardProps, prevState: DashboardState) {
		if (prevState.letters.length === 0 && this.state.letters.length > 0) {
			this.setState({
				letterSelect: this.state.letters.map((v) => v.value),
			});
			this.cargarCartas(this.state.letters.map((v) => v.value));
		}
	}

	async cargarInformacion(e?: any) {
		const { enqueueSnackbar } = this.props;
		const { optionsMainChart } = this.state;

		const r = await fetchApi('data/charts/general', {
			cartas: e || this.state.letterSelect,
		});

		if (r.ok) {
			const {
				general: { total, pdf, imagen, registros },
			} = r.data;
			this.setState({
				letters: registros.map((r: any) => ({
					label: r.name,
					value: r._id,
				})),
				dataMainChart: generarDataMainChart(total, imagen, pdf),
				optionsMainChart: {
					...optionsMainChart,
					xaxis: {
						categories: registros.map((v: any) => v.name),
					},
				},
			});
		} else {
			enqueueSnackbar(r.data, {
				variant: 'error',
			});
		}
	}

	async cargarCartas(e?: any) {
		const { enqueueSnackbar } = this.props;
		const { optionsSecondaryChart2 } = this.state;

		const r = await fetchApi('data/charts/letters', {
			cartas: e || this.state.letterSelect,
		});

		if (r.ok) {
			const {
				cartas: {
					abiertas,
					sinAbrir,
					distribucion: { valores, elementos },
				},
			} = r.data;
			const dataSecChart1 = [abiertas, sinAbrir];
			this.setState({
				dataSecondaryChart1: dataSecChart1,
				dataSecondaryChart2: valores,
				optionsSecondaryChart2: {
					...optionsSecondaryChart2,
					labels: elementos,
				},
			});
		} else {
			enqueueSnackbar(r.data, {
				variant: 'error',
			});
		}
	}

	render() {
		const {
			letterSelect,
			letters,
			optionsMainChart,
			dataMainChart,
			optionsSecondaryChart1,
			dataSecondaryChart1,
			optionsSecondaryChart2,
			dataSecondaryChart2,
		} = this.state;

		return (
			<Grid spacing={2} container justify='center'>
				<Grid item xs={12} container spacing={2} direction='row'>
					<Grid item xs={12}>
						<Chart
							options={optionsMainChart}
							series={dataMainChart}
							type='bar'
							height={300}
						/>
					</Grid>
					<Grid item xs={12} style={{ marginBottom: '1rem' }}>
						<MultipleSelect
							label='Selecciona las cartas para la grafica'
							value={letterSelect}
							onChange={(e) => {
								this.cargarCartas(e);
								this.setState({ letterSelect: e });
							}}
							items={letters}
						/>
					</Grid>
					<Grid item xs={12} container>
						<Grid item xs={6}>
							<Chart
								options={optionsSecondaryChart1}
								series={dataSecondaryChart1}
								type='donut'
								height={250}
							/>
						</Grid>
						<Grid item xs={6}>
							<Chart
								options={optionsSecondaryChart2}
								series={dataSecondaryChart2}
								type='donut'
								height={250}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

export default withSnackbar(withStyles(styles)(Dashboard));
