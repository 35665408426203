import { ThemeProvider } from '@material-ui/core';
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Index from './components/index';
import Login from './components/login';
import User from './components/user';
import Admin from './components/admin';
import theme from './miui/theme';
import { SnackbarProvider } from 'notistack';

class App extends React.Component<{}, {}, any> {
	render() {
		return (
			<Router>
				<ThemeProvider theme={theme}>
					<SnackbarProvider
						maxSnack={4}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						autoHideDuration={4000}
					>
						<div>
							<Switch>
								<Route exact path='/' component={Index} />
								<Route path='/login' component={Login} />
								<Route path='/user' component={User} />
								<Route path='/admin' component={Admin} />
							</Switch>
						</div>
					</SnackbarProvider>
				</ThemeProvider>
			</Router>
		);
	}
}

export default App;
