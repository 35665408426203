import React from 'react';

import { connect } from 'react-redux';

import { deleteSession, setSession } from '../redux/actions';

import { Theme, withStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { deleteCookieSession } from '../cookies';
import { RootState } from '../redux/reducers';
import { getSession } from '../redux/selectors';
import { ISession } from '../types';

import { Styles } from '@material-ui/core/styles/withStyles';
import CustomAppBar from './appbar';

import componentSession from '../session';

import Users from './admin/users';

const styles: Styles<Theme, {}> = (theme) => ({});

interface AdminProps extends RouteComponentProps {
	classes: Record<string, string>;
	setSession: typeof setSession;
	deleteSession: typeof deleteSession;
	session: ISession | null;
}

interface AdminState {}

class Admin extends React.Component<AdminProps, AdminState> {
	componentDidMount() {
		componentSession(this.props, 'admin');
	}

	componentDidUpdate(oldProps: AdminProps) {
		const { session, history } = this.props;
		if (oldProps.session && !session) {
			history.replace('/login');
		}
	}

	logout() {
		return (e: any) => {
			const { deleteSession } = this.props;
			deleteCookieSession();
			deleteSession();
		};
	}

	render() {
		return (
			<React.Fragment>
				<CustomAppBar title='Administrador' onLogout={this.logout()} />
				<Users />
			</React.Fragment>
		);
	}
}

export default withRouter(
	withStyles(styles)(
		connect(
			(store: RootState, ownProps) => {
				return { session: getSession(store), ...ownProps };
			},
			{ setSession, deleteSession }
		)(Admin)
	)
);
