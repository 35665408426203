import { Card, CardContent, Theme, Typography } from '@material-ui/core';
import withStyles, { Styles } from '@material-ui/core/styles/withStyles';
import React from 'react';
import Dropzone, { DropzoneState } from 'react-dropzone';

import {
	NoteAddRounded as FileIcon,
	ReportProblemOutlined as ErrorIcon,
	CheckCircle as SuccessIcon,
	InfoOutlined as InfoIcon,
} from '@material-ui/icons';

const styles: Styles<Theme, {}> = (theme) => ({
	card: {
		borderStyle: 'dashed',
		borderWidth: 4,
	},
	cardActive: {
		borderStyle: 'dashed',
		borderWidth: 4,
		borderColor: theme.palette.primary.main,
	},
	cardActiveWrong: {
		borderStyle: 'dashed',
		borderWidth: 4,
		borderColor: theme.palette.error.main,
	},
	dropzoneRoot: {
		height: 100,
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		flexDirection: 'column',
	},
});

interface FullDropZoneProps {
	classes: Record<string, string>;
	label?: string;
	accept?: string | string[];
	onDrop?: (files: File[]) => void;
}
interface FullDropZoneState {}

class FullDropZone extends React.Component<
	FullDropZoneProps,
	FullDropZoneState
> {
	getContent({
		isDragActive,
		isDragAccept,
		isFileDialogActive,
		acceptedFiles,
		fileRejections,
	}: DropzoneState) {
		const { classes, label } = this.props;
		let finalContent = (
			<React.Fragment>
				<FileIcon fontSize='large' color='disabled' />
				<Typography variant='h6' color='textSecondary' align='center'>
					{label || 'Arrastra aqui tu archivo'}
				</Typography>
			</React.Fragment>
		);

		if (isDragActive && isDragAccept) {
			finalContent = (
				<React.Fragment>
					<SuccessIcon fontSize='large' color='primary' />
					<Typography variant='h6' color='primary' align='center'>
						{isFileDialogActive
							? 'Seleccionando archivo'
							: 'Suelta para seleccionar el archivo'}
					</Typography>
				</React.Fragment>
			);
		}

		if (isDragActive && !isDragAccept) {
			finalContent = (
				<React.Fragment>
					<ErrorIcon fontSize='large' color='error' />
					<Typography variant='h6' color='error' align='center'>
						Tipo de archivo o cantidad de archivos incorrecta
					</Typography>
				</React.Fragment>
			);
		}

		if (isFileDialogActive) {
			finalContent = (
				<React.Fragment>
					<InfoIcon fontSize='large' color='primary' />
					<Typography variant='h6' color='primary' align='center'>
						{isFileDialogActive
							? 'Seleccionando archivo'
							: 'Suelta para seleccionar el archivo'}
					</Typography>
				</React.Fragment>
			);
		}

		if (!isDragActive && !isFileDialogActive && fileRejections.length > 0) {
			finalContent = (
				<React.Fragment>
					<ErrorIcon fontSize='large' color='error' />
					<Typography variant='h6' color='error' align='center'>
						Tipo de archivo o cantidad de archivos incorrecta
					</Typography>
				</React.Fragment>
			);
		}

		if (!isDragActive && !isFileDialogActive && acceptedFiles.length > 0) {
			finalContent = (
				<React.Fragment>
					<SuccessIcon fontSize='large' color='primary' />
					<Typography variant='body1' color='primary' align='center'>
						{acceptedFiles[0].name}
					</Typography>
				</React.Fragment>
			);
		}

		return <div className={classes.dropzoneRoot}>{finalContent}</div>;
	}

	getCardClass({
		isDragActive,
		isDragAccept,
		isFileDialogActive,
		acceptedFiles,
		fileRejections,
	}: DropzoneState) {
		const { classes } = this.props;
		if (isDragActive && isDragAccept) return classes.cardActive;
		if (isDragActive && !isDragAccept) return classes.cardActiveWrong;
		if (isFileDialogActive) return classes.cardActive;
		if (fileRejections.length > 0) return classes.cardActiveWrong;
		if (acceptedFiles.length > 0) return classes.cardActive;
		return classes.card;
	}

	render() {
		const { accept, onDrop } = this.props;

		return (
			<div
				style={{
					margin: '0.5rem',
				}}
			>
				<Dropzone onDrop={onDrop} accept={accept} maxFiles={1}>
					{(state) => {
						const { getRootProps, getInputProps } = state;

						return (
							<Card
								variant='outlined'
								className={this.getCardClass(state)}
							>
								<CardContent {...getRootProps()}>
									<input {...getInputProps()} />
									{this.getContent(state)}
								</CardContent>
							</Card>
						);
					}}
				</Dropzone>
			</div>
		);
	}
}

export default withStyles(styles)(FullDropZone);
