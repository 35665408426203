import React from 'react';

import { connect } from 'react-redux';

import { deleteSession, setSession } from '../redux/actions';

import {
	Drawer,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Theme,
	Toolbar,
	withStyles,
} from '@material-ui/core';
import {
	Route,
	RouteComponentProps,
	Switch,
	withRouter
} from 'react-router-dom';
import { deleteCookieSession } from '../cookies';
import { RootState } from '../redux/reducers';
import { getSession } from '../redux/selectors';
import { ISession } from '../types';

import {
	DashboardRounded as DashboardIcon,
	DraftsRounded as DraftsIcon,
} from '@material-ui/icons';

import { Styles } from '@material-ui/core/styles/withStyles';
import CustomAppBar from './appbar';

import componentSession from '../session';
import Dashboard from './user/dashboard';
import Letters from './user/letters';

const drawerWidth = 240;
const styles: Styles<Theme, {}> = (theme) => ({
	root: {
		display: 'flex',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerContainer: {
		overflow: 'auto',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(1),
	},
	dashboardIcon: {
		color: theme.palette.primary.main,
	},
});

interface UserProps extends RouteComponentProps {
	classes: Record<string, string>;
	setSession: typeof setSession;
	deleteSession: typeof deleteSession;
	session: ISession | null;
}

interface UserState {
	pdf: File[];
	csv: File[];
}

class User extends React.Component<UserProps, UserState> {
	state = {
		pdf: [],
		csv: [],
	};

	componentDidMount() {
		componentSession(this.props, 'user');
	}

	componentDidUpdate(oldProps: UserProps) {
		const { session, history } = this.props;
		if (oldProps.session && !session) {
			console.log('se cerro la sesion');
			history.replace('/login');
		}
	}

	logout() {
		return (e: any) => {
			const { deleteSession } = this.props;
			deleteCookieSession();
			deleteSession();
		};
	}

	navigateTo(url: string) {
		return (e: any) => this.props.history.push(url);
	}

	render() {
		const {
			match: { url, path },
			classes,
		} = this.props;

		return (
			<div className={classes.root}>
				<CustomAppBar
					title='Generar cartas'
					prominent={false}
					onLogout={this.logout()}
				/>
				<Drawer
					variant='permanent'
					className={classes.drawer}
					classes={{
						paper: classes.drawerPaper,
					}}
				>
					<Toolbar />
					<div className={classes.drawerContainer}>
						<List>
							<ListItem button onClick={this.navigateTo(url)}>
								<ListItemIcon>
									<DashboardIcon
										className={classes.dashboardIcon}
									/>
								</ListItemIcon>
								<ListItemText primary='Dashboard' />
							</ListItem>
							<ListItem
								button
								onClick={this.navigateTo(`${url}/letters`)}
							>
								<ListItemIcon>
									<DraftsIcon color='secondary' />
								</ListItemIcon>
								<ListItemText primary='Cartas' />
							</ListItem>
						</List>
					</div>
				</Drawer>
				<main className={classes.content}>
					<Toolbar />
					<Switch>
						<Route exact path={path}>
							<Dashboard />
						</Route>
						<Route exact path={`${path}/letters`}>
							<Letters />
						</Route>
					</Switch>
				</main>
			</div>
		);
	}
}

export default withRouter(
	withStyles(styles)(
		connect(
			(store: RootState, ownProps) => {
				return { session: getSession(store), ...ownProps };
			},
			{ setSession, deleteSession }
		)(User)
	)
);
