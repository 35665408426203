import {
	Button,
	Card,
	CardContent,
	Checkbox,
	FormControlLabel,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	TextField,
	Theme,
	Typography
} from '@material-ui/core';
import withStyles, { Styles } from '@material-ui/core/styles/withStyles';
import React, { MouseEvent } from 'react';

import { withSnackbar, WithSnackbarProps } from 'notistack';
import fetchApi, { fetchApiDownload, fetchApiFile } from '../../fetch';
import ContextMenu, { IMenuItem } from '../contextMenu';
import Dialog from '../dialog';
import Dropzone from '../dropzone';
import LoadingDialog from '../loadingDialog';
import Table, { ITableHeader } from '../table';

export interface IPDFile {
	_id: string;
	name: string;
	file: string;
	dateUpload: Date;
	needPassword: boolean;
}

interface LettersProps extends WithSnackbarProps {
	classes: Record<string, string>;
}

interface LettersState {
	createDialog: boolean;
	deleteDialog: boolean;
	uploadDataDialog: boolean;
	uploadValidationDialog: boolean;
	downloadDataDialog: boolean;
	deleteDataDialog: boolean;
	letter: IPDFile;
	letterFile: File | null;
	letters: IPDFile[];
	csvFile: File | null;
	loading: boolean;
	headers: ITableHeader[];
	contextMenuCoords: { x: number; y: number } | null;
	downloadWithURLs: boolean;
	urlOfImage: boolean;
	dataResultDialog: boolean;
	dataCorrectos: number;
	dataErroneos: number;
	dataErroneosDetalleLineas: string;
	camposInexistentes: string[];
}

const styles: Styles<Theme, {}> = (theme) => ({
	root: {
		margin: `${theme.spacing(2)}px 0px !important`,
	},
	title: {
		flexGrow: 1,
	},
	uploadDialog: {},
	primaryText: {
		color: theme.palette.primary.main,
	},
	errorText: {
		color: theme.palette.error.main,
	},
});

const defaultLetter: IPDFile = {
	_id: '',
	name: '',
	file: '',
	dateUpload: new Date(),
	needPassword: false,
};

const defaultState: LettersState = {
	createDialog: false,
	deleteDialog: false,
	letter: defaultLetter,
	letters: [],
	letterFile: null,
	loading: false,
	headers: [
		{
			field: 'name',
			name: 'Nombre',
		},
		{
			field: 'dateUpload',
			name: 'Fecha de carga',
		},
	],
	contextMenuCoords: null,
	uploadDataDialog: false,
	downloadDataDialog: false,
	csvFile: null,
	deleteDataDialog: false,
	downloadWithURLs: false,
	urlOfImage: false,
	dataResultDialog: false,
	dataCorrectos: 0,
	dataErroneos: 0,
	dataErroneosDetalleLineas: '',
	uploadValidationDialog: false,
	camposInexistentes: [],
};

class Letters extends React.Component<LettersProps, LettersState> {
	state = defaultState;
	interval: any = -1;

	componentDidMount() {
		this.loadLetters();
		this.interval = setInterval(() => this.loadLetters(), 2000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	async loadLetters() {
		const r = await fetchApi('PDF/list', {});
		const { enqueueSnackbar } = this.props;
		if (r.ok) {
			this.setState({
				letters: r.data.registros,
			});
		} else {
			enqueueSnackbar(r.data, {
				variant: 'error',
			});
		}
	}

	deleteLetter() {
		return async (e: any) => {
			const { letter } = this.state;
			const { enqueueSnackbar } = this.props;
			this.setState({ loading: true });
			const r = await fetchApi('PDF/delete/' + letter._id, {});
			if (r.ok) {
				this.setState({
					deleteDialog: false,
					letter: defaultLetter,
					contextMenuCoords: null,
					loading: false,
				});
				enqueueSnackbar('Carta eliminada con exito', {
					variant: 'success',
				});
			} else {
				enqueueSnackbar(r.data, {
					variant: 'error',
				});
				this.setState({ loading: false });
			}
			this.loadLetters();
		};
	}

	uploadLetter() {
		return async (e: any) => {
			const { letter, letterFile } = this.state;
			const { enqueueSnackbar } = this.props;
			if (letterFile && letter.name !== '') {
				this.setState({ loading: true });
				const fd = new FormData();
				fd.append('pdf', letterFile);
				fd.append('name', letter.name);
				fd.append(
					'needPassword',
					letter.needPassword ? 'true' : 'false'
				);

				let url = 'PDF/register';
				if (letter._id !== '') {
					url = `PDF/edit/${letter._id}`;
				}
				const r = await fetchApiFile(url, fd);

				if (r.ok) {
					enqueueSnackbar(
						`Carta ${letter._id === '' ? 'cargada' : 'actualizada'
						} con exito`,
						{
							variant: 'success',
						}
					);

					this.setState({
						createDialog: false,
						letter: defaultLetter,
						letterFile: null,
						contextMenuCoords: null,
						loading: false,
					});
				} else {
					enqueueSnackbar(r.data, {
						variant: 'error',
					});
					this.setState({ loading: false });
				}

				this.loadLetters();
			} else {
				enqueueSnackbar(
					'No has seleccionado un archivo ni introducido un nombre',
					{
						variant: 'error',
					}
				);
			}
		};
	}

	uploadData() {
		return async (e: any) => {
			const { letter, csvFile } = this.state;
			const { enqueueSnackbar } = this.props;
			if (letter._id !== '' && csvFile !== null) {
				this.setState({ loading: true });
				const fd = new FormData();
				fd.append('csv', csvFile);

				const r = await fetchApiFile('data/upload/' + letter._id, fd);

				if (r.ok) {
					enqueueSnackbar('Registros cargados con exito', {
						variant: 'success',
					});

					const erroneos: number = r.data.erroneos.length;
					const sliceErroneos = (r.data.erroneos as any[]).slice(
						0,
						5
					);
					const lineas = sliceErroneos
						.map((v: any) => v.line)
						.join(',');

					this.setState({
						letter: defaultLetter,
						contextMenuCoords: null,
						loading: false,
						dataResultDialog: true,
						dataCorrectos: r.data.correctos,
						dataErroneos: erroneos,
						dataErroneosDetalleLineas: `Error en las lineas ${lineas}${sliceErroneos.length < erroneos
							? ' y otras ' +
							(erroneos - sliceErroneos.length) +
							' lineas.'
							: '.'
							}`,
						camposInexistentes: [],
						uploadValidationDialog: false,
					});
				} else {
					enqueueSnackbar(r.data, {
						variant: 'error',
					});
					this.setState({ loading: false });
				}
			} else {
				enqueueSnackbar('No has seleccionado ningun archivo', {
					variant: 'error',
				});
			}
		};
	}

	async checkData() {
		const { letter, csvFile } = this.state;
		const { enqueueSnackbar } = this.props;
		if (letter._id !== '' && csvFile !== null) {
			this.setState({ loading: true });

			const reader = new FileReader();
			reader.readAsText(csvFile, 'utf-8');

			const campos: any = (
				await new Promise<string>((solve, reject) => {
					reader.addEventListener('load', (e) => {
						const result = e.target!.result;
						solve(result as string);
					});
				})
			)
				.split(/(\r?\n|\r)/)
				.shift()
				?.split(',');

			const r = await fetchApi('data/upload/check/' + letter._id, {
				campos,
			});

			if (r.ok) {
				this.setState({
					camposInexistentes: r.data.camposInexistentes,
					uploadValidationDialog: true,
					uploadDataDialog: false,
					loading: false,
				});
			} else {
				enqueueSnackbar(r.data, {
					variant: 'error',
				});
				this.setState({ loading: false });
			}
		} else {
			enqueueSnackbar('No has seleccionado ningun archivo', {
				variant: 'error',
			});
		}
	}

	deleteData() {
		return async (e: any) => {
			const { letter } = this.state;
			const { enqueueSnackbar } = this.props;
			if (letter._id !== '') {
				this.setState({ loading: true });
				const r = await fetchApi('data/deleteAll/' + letter._id, {});

				if (r.ok) {
					enqueueSnackbar('Registros eliminados con exito', {
						variant: 'success',
					});
					this.setState({
						deleteDataDialog: false,
						letter: defaultLetter,
						contextMenuCoords: null,
						loading: false,
					});
				} else {
					enqueueSnackbar(r.data, {
						variant: 'error',
					});
					this.setState({ loading: false });
				}
			}
		};
	}

	downloadData() {
		return async (e: any) => {
			const { letter, downloadWithURLs, urlOfImage } = this.state;
			const { enqueueSnackbar } = this.props;
			if (letter._id !== '') {
				this.setState({ loading: true });
				const r = await fetchApiDownload(
					'data/download/' +
					letter._id +
					(urlOfImage ? '?select=2' : '?select=1') +
					(downloadWithURLs ? '&urls=true' : ''),
					{}
				);

				if (r.ok) {
					const blob = new Blob([r.data], { type: 'text/csv' });
					const encodedCSVUri = URL.createObjectURL(blob);
					const link = document.createElement('a');
					link.setAttribute('href', encodedCSVUri);
					link.setAttribute(
						'download',
						`Registros ${letter.name
						} al ${new Date().toLocaleString()}.csv`
					);

					document.body.appendChild(link);
					link.click();
					link.remove();

					enqueueSnackbar('Registros obtenidos con exito', {
						variant: 'success',
					});
					this.setState({
						downloadDataDialog: false,
						letter: defaultLetter,
						contextMenuCoords: null,
						loading: false,
					});
				} else {
					enqueueSnackbar(r.data, {
						variant: 'error',
					});
					this.setState({ loading: false });
				}
			}
		};
	}

	handleLetter(prop: keyof IPDFile) {
		return (e: any) =>
			this.setState({
				letter: { ...this.state.letter, [prop]: e.target.value },
			});
	}

	handleLetterNeedPassword() {
		return (e: any) =>
			this.setState({
				letter: { ...this.state.letter, needPassword: e.target.checked },
			});
	}

	handleLetterFile() {
		return (files: File[]) => this.setState({ letterFile: files[0] });
	}

	handleCSVFile() {
		return (files: File[]) => this.setState({ csvFile: files[0] });
	}

	handleContextMenuClose() {
		return (e: any) =>
			this.setState({
				contextMenuCoords: null,
				letter: defaultLetter,
			});
	}

	handleContextMenuOpen() {
		return (e: MouseEvent, item: IPDFile) => {
			this.setState({
				letter: item,
				contextMenuCoords: {
					x: e.pageX,
					y: e.pageY,
				},
			});
		};
	}

	handleContextMenuItemClick() {
		return (e: any, item: IMenuItem) => {
			switch (item.value) {
				case 'delete':
					this.setState({
						deleteDialog: true,
					});
					break;
				case 'edit':
					this.setState({ createDialog: true });
					break;
				case 'uploadData':
					this.setState({ uploadDataDialog: true });
					break;
				case 'downloadData':
					this.setState({ downloadDataDialog: true });
					break;
				case 'deleteData':
					this.setState({ deleteDataDialog: true });
					break;
			}
		};
	}

	render() {
		const { classes } = this.props;
		const {
			headers,
			letters,
			contextMenuCoords,
			letter,
			createDialog,
			deleteDialog,
			uploadDataDialog,
			downloadDataDialog,
			deleteDataDialog,
			loading,
			downloadWithURLs,
			urlOfImage,
			dataResultDialog,
			dataErroneos,
			dataCorrectos,
			dataErroneosDetalleLineas,
			uploadValidationDialog,
			camposInexistentes,
		} = this.state;

		return (
			<React.Fragment>
				<Grid container justify='center'>
					<Grid item xs={10} className={classes.root}>
						<Card>
							<CardContent>
								<Grid
									container
									justify='center'
									direction='row'
									spacing={2}
								>
									<Grid container item xs={12}>
										<Typography
											variant='h6'
											className={classes.title}
										>
											Cartas
										</Typography>
										<Button
											variant='outlined'
											color='primary'
											onClick={(e) =>
												this.setState({
													createDialog: true,
												})
											}
										>
											Subir carta
										</Button>
									</Grid>
									<Grid item xs={12}>
										<Table
											headers={headers}
											items={letters.map((l) => ({
												...l,
												dateUpload:
													l.dateUpload.toLocaleString(),
											}))}
											onItemClick={this.handleContextMenuOpen()}
											tooltip='Haz click para ver las opciones'
										/>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
				<ContextMenu
					coords={contextMenuCoords}
					onClose={this.handleContextMenuClose()}
					items={[
						{ label: 'Subir otro archivo', value: 'edit' },
						{ label: 'Eliminar carta', value: 'delete' },
						{ label: 'Cargar registros', value: 'uploadData' },
						{ label: 'Descargar registros', value: 'downloadData' },
						{
							label: 'Eliminar todos los registros',
							value: 'deleteData',
						},
					]}
					onItemClick={this.handleContextMenuItemClick()}
				/>
				<Dialog
					open={deleteDialog}
					onClose={(e) => this.setState({ deleteDialog: false })}
					title='¿Estas seguro?'
					positiveAction='eliminar'
					negativeAction='cancelar'
					onNegativeAction={(e) =>
						this.setState({ deleteDialog: false })
					}
					onPositiveAction={this.deleteLetter()}
				>
					<Typography variant='body1' align='justify'>
						Eliminaras la carta{' '}
						<span className={classes.primaryText}>
							{letter.name}
						</span>
						, por lo que sus enlaces dejaran de servir.
					</Typography>
				</Dialog>
				<Dialog
					open={createDialog}
					onClose={(e) =>
						this.setState({
							createDialog: false,
							letterFile: null,
							letter: defaultLetter,
						})
					}
					title={letter._id === '' ? 'Nueva carta' : 'Editar carta'}
					positiveAction='subir'
					negativeAction='cancelar'
					onNegativeAction={(e) =>
						this.setState({
							createDialog: false,
							letterFile: null,
							letter: defaultLetter,
						})
					}
					onPositiveAction={this.uploadLetter()}
					autoWidth
				>
					<Grid
						container
						className={classes.uploadDialog}
						direction='column'
						spacing={2}
					>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label='Nombre'
								helperText='Nombre para identificar la carta'
								value={letter.name}
								onChange={this.handleLetter('name')}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='subtitle2'>Archivo</Typography>
						</Grid>
						<Grid item xs={12}>
							<Dropzone
								label={'Arrastra o selecciona la plantilla PDF'}
								accept={'application/pdf'}
								onDrop={this.handleLetterFile()}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControlLabel
								control={
									<Checkbox
										checked={letter.needPassword}
										onChange={this.handleLetterNeedPassword()}
										color='primary'
									/>
								}
								label='Con contraseña'
							/>
						</Grid>
					</Grid>
				</Dialog>
				<Dialog
					open={uploadDataDialog}
					onClose={(e) =>
						this.setState({
							uploadDataDialog: false,
							csvFile: null,
						})
					}
					title='Subir registros para la carta'
					positiveAction='subir'
					negativeAction='cancelar'
					onNegativeAction={(e) =>
						this.setState({
							uploadDataDialog: false,
							csvFile: null,
						})
					}
					onPositiveAction={(e) => this.checkData()}
					autoWidth
				>
					<Dropzone
						label={'Arrastra o selecciona el archivo CSV'}
						accept={[
							'.csv',
							'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
							'application/vnd.ms-excel',
						]}
						onDrop={this.handleCSVFile()}
					/>
				</Dialog>
				<Dialog
					open={deleteDataDialog}
					onClose={(e) => this.setState({ deleteDataDialog: false })}
					title='¿Estas seguro?'
					positiveAction='eliminar'
					negativeAction='cancelar'
					onNegativeAction={(e) =>
						this.setState({ deleteDataDialog: false })
					}
					onPositiveAction={this.deleteData()}
				>
					<Typography variant='body1' align='justify'>
						Hacer esto eliminara todos los registros asociados a
						esta carta, haciendo invalido su enlace de acceso.
					</Typography>
				</Dialog>
				<Dialog
					open={uploadValidationDialog}
					onClose={(e) =>
						this.setState({
							uploadValidationDialog: false,
							csvFile: null,
						})
					}
					title={
						camposInexistentes.length > 0
							? '¿Estas seguro?'
							: 'Archivo validado'
					}
					positiveAction='continuar'
					negativeAction='cancelar subida'
					onNegativeAction={(e) =>
						this.setState({
							uploadValidationDialog: false,
							csvFile: null,
						})
					}
					onPositiveAction={this.uploadData()}
				>
					{camposInexistentes.length > 0 ? (
						<Typography variant='body1'>
							{camposInexistentes.length === 1
								? 'El campo '
								: 'Los campos '}
							<span className={classes.errorText}>
								{camposInexistentes.join(', ')}
							</span>{' '}
							no se{' '}
							{camposInexistentes.length === 1
								? 'encontro'
								: 'encontraron'}{' '}
							en el PDF de la carta. ¿Deseas subir aun asi este
							archivo?{' '}
						</Typography>
					) : (
						<Typography variant='body1'>
							No se encontraron problemas con el archivo cargado
						</Typography>
					)}
				</Dialog>
				<Dialog
					open={downloadDataDialog}
					onClose={(e) => this.setState({ downloadDataDialog: false })}
					title='Descargar registros'
					positiveAction='descargar'
					negativeAction='cancelar'
					onNegativeAction={(e) =>
						this.setState({ downloadDataDialog: false })
					}
					onPositiveAction={this.downloadData()}
				>
					<Typography variant='body1' align='justify'>
						Se descargaran todos los registros asociados a esta
						carta. Esto puede tomar algo de tiempo.
					</Typography>
					<List>
						<ListItem
							button
							onClick={(e) =>
								this.setState({
									downloadWithURLs: !downloadWithURLs,
								})
							}
						>
							<ListItemIcon>
								<Checkbox checked={downloadWithURLs} />
							</ListItemIcon>
							<ListItemText
								primary='Incluir enlaces de acceso'
								secondary='Si no se selecciona esta opcion se descargaran los registros sin su url'
							/>
						</ListItem>
						<ListItem
							button
							onClick={(e) =>
								this.setState({
									urlOfImage: !urlOfImage,
								})
							}
						>
							<ListItemIcon>
								<Checkbox checked={urlOfImage} />
							</ListItemIcon>
							<ListItemText
								primary='URL de imagen'
								secondary='El csv solo incluira los enlaces para imagen'
							/>
						</ListItem>
					</List>
				</Dialog>
				<Dialog
					open={dataResultDialog}
					onClose={(e) => this.setState({ dataResultDialog: false })}
					positiveAction='enterado'
					onPositiveAction={(e) =>
						this.setState({ dataResultDialog: false })
					}
					title='Registros cargados'
				>
					<p>
						<Typography variant='body1' color='primary'>
							{dataCorrectos} registros creados exitosamente.
						</Typography>
					</p>
					<p>
						<Typography variant='body1' color='error'>
							Han fallado en crearse {dataErroneos} registros.
						</Typography>
					</p>
					{dataErroneos > 0 && (
						<p>
							<Typography
								variant='body1'
								align='justify'
								color='textSecondary'
							>
								{dataErroneosDetalleLineas}
							</Typography>
						</p>
					)}
				</Dialog>
				<LoadingDialog open={loading} />
			</React.Fragment>
		);
	}
}

export default withSnackbar(withStyles(styles)(Letters));
