import React, { MouseEvent } from 'react';

import {
	Button,
	Card,
	CardContent,
	Grid,
	Theme,
	Typography,
	withStyles,
} from '@material-ui/core';

import { Styles } from '@material-ui/core/styles/withStyles';

import Dialog from '../dialog';
import UserDialog, { IUser } from '../userDialog';
import Table, { ITableHeader } from '../table';
import ContextMenu, { IMenuItem } from '../contextMenu';
import LoadingDialog from '../loadingDialog';
import fetchApi from '../../fetch';
import { withSnackbar, WithSnackbarProps } from 'notistack';

const styles: Styles<Theme, {}> = (theme) => ({
	root: {
		margin: `${theme.spacing(4)}px 0px !important`,
	},
	tableContainer: {
		minHeight: '60vh',
	},
	title: {
		flexGrow: 1,
	},
});

const defaultUser: IUser = {
	_id: '',
	name: '',
	username: '',
	password: '',
	type: 1,
};

interface UsersProps extends WithSnackbarProps {
	classes: Record<string, string>;
}

interface UsersState {
	headers: ITableHeader[];
	users: IUser[];
	usuario: IUser;
	showDialogUser: boolean;
	deleteDialog: boolean;
	contextMenuCoords: { x: number; y: number } | null;
	loading: boolean;
}

class Users extends React.Component<UsersProps, UsersState> {
	interval: any = -1;

	state = {
		headers: [
			{
				field: 'name',
				name: 'Nombre',
			},
			{
				field: 'username',
				name: 'Usuario',
			},
			{
				field: 'cartas',
				name: 'Conteo de cartas',
			},
			{
				field: 'typeLabel',
				name: ' Nivel',
			},
		],
		users: [],
		usuario: defaultUser,
		showDialogUser: false,
		contextMenuCoords: null,
		deleteDialog: false,
		loading: false,
	};

	componentDidMount() {
		this.cargarUsuarios();
		this.interval = setInterval(() => this.cargarUsuarios(), 2000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	async cargarUsuarios() {
		const r = await fetchApi('user/list', {});
		const { enqueueSnackbar } = this.props;

		if (r.ok) {
			this.setState({
				users: r.data.registros.map(
					({ _id, username, name, type, cartas }: any) => ({
						_id,
						name,
						username,
						type,
						password: '',
						cartas: '' + cartas,
					})
				),
			});
		} else {
			enqueueSnackbar(r.data, { variant: 'error' });
		}
	}

	eliminarUsuario() {
		return async (e: any) => {
			const usuario = this.state.usuario;
			const { enqueueSnackbar } = this.props;
			this.setState({ loading: true });
			const r = await fetchApi('user/delete/' + usuario._id, {});

			if (r.ok) {
				enqueueSnackbar('Usuario eliminado con exito', {
					variant: 'success',
				});
				this.setState({
					deleteDialog: false,
					usuario: defaultUser,
					loading: false,
				});
				this.cargarUsuarios();
			} else {
				this.setState({ loading: false });
				enqueueSnackbar(r.data, {
					variant: 'error',
				});
			}
		};
	}

	handleContextMenuClose() {
		return (e: any) =>
			this.setState({
				contextMenuCoords: null,
				usuario: defaultUser,
			});
	}

	handleContextMenuOpen() {
		return (e: MouseEvent, item: IUser) => {
			this.setState({
				usuario: item,
				contextMenuCoords: {
					x: e.pageX,
					y: e.pageY,
				},
			});
		};
	}

	handleContextMenuItemClick() {
		return (e: any, i: IMenuItem) => {
			if (i.value === 'edit') {
				this.setState({
					contextMenuCoords: null,
					showDialogUser: true,
				});
			} else {
				this.setState({
					contextMenuCoords: null,
					deleteDialog: true,
				});
			}
		};
	}

	handleUserDialog(v: boolean) {
		return (e: any) => this.setState({ showDialogUser: v });
	}

	handleDeleteDialog(v: boolean) {
		return (e: any) => this.setState({ deleteDialog: v });
	}

	render() {
		const { classes } = this.props;
		const {
			headers,
			users,
			usuario,
			showDialogUser,
			contextMenuCoords,
			deleteDialog,
			loading,
		} = this.state;

		return (
			<React.Fragment>
				<Grid container justify='center'>
					<Grid item xs={8} className={classes.root}>
						<Card>
							<CardContent>
								<Grid
									container
									justify='center'
									direction='row'
									spacing={2}
								>
									<Grid container item xs={12}>
										<Typography
											variant='h6'
											className={classes.title}
										>
											Usuarios
										</Typography>
										<Button
											variant='outlined'
											color='primary'
											onClick={this.handleUserDialog(
												true
											)}
										>
											Nuevo usuario
										</Button>
									</Grid>
									<Grid item xs={12}>
										<Table
											headers={headers}
											items={users.map((u: any) => ({
												...u,
												typeLabel:
													u.type === 1
														? 'Usuario'
														: 'Administrador',
											}))}
											onItemClick={this.handleContextMenuOpen()}
											tooltip='Haz click para ver las opciones'
										/>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
				<ContextMenu
					coords={contextMenuCoords}
					onClose={this.handleContextMenuClose()}
					items={[
						{ label: 'Editar', value: 'edit' },
						{ label: 'Eliminar', value: 'delete' },
					]}
					onItemClick={this.handleContextMenuItemClick()}
				/>
				<UserDialog
					open={showDialogUser}
					onClose={() =>
						this.setState({
							showDialogUser: false,
							usuario: defaultUser,
						})
					}
					value={usuario}
					onSubmit={() => {
						this.setState({
							showDialogUser: false,
							usuario: defaultUser,
							loading: false,
						});
						this.cargarUsuarios();
					}}
					onToggleLoading={(e: boolean) =>
						this.setState({ loading: e })
					}
					enqueueSnackbar={this.props.enqueueSnackbar}
				/>
				<Dialog
					title='¿Estás seguro?'
					positiveAction='eliminar'
					negativeAction='cancelar'
					open={deleteDialog}
					onClose={(e) => this.setState({ deleteDialog: false })}
					onNegativeAction={(e) =>
						this.setState({
							deleteDialog: false,
							usuario: defaultUser,
						})
					}
					onPositiveAction={this.eliminarUsuario()}
				>
					<Typography variant='body1'>
						Hacer esto bloqueara el acceso a {usuario.username} de
						la plataforma.
					</Typography>
				</Dialog>
				<LoadingDialog open={loading} />
			</React.Fragment>
		);
	}
}

export default withSnackbar(withStyles(styles)(Users));
