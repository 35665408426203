import 'isomorphic-fetch';
import { getCookieSession, deleteCookieSession } from './cookies';
import { ISession } from './types';

export async function fetchApiFile(
	url: string,
	body: any,
	token: boolean = true
) {
	const options: RequestInit = {
		body: body,
		method: 'POST',
		headers: {},
	};

	if (token) {
		const session: ISession = getCookieSession();
		options.headers = {
			Authorization: `Basic ${session.token}`,
		};
	}

	try {
		const r = await fetch(`/api/${url}`, options);
		const data = await (r.ok ? r.json() : r.text());
		return { ok: r.ok, data };
	} catch (e) {
		console.log(e);
		return {
			ok: false,
			data: 'Ha ocurrido un error',
		};
	}
}

export async function fetchApiDownload(
	url: string,
	body?: any,
	token: boolean = true
) {
	const options: RequestInit = {
		body: JSON.stringify(body),
		method: body ? 'POST' : 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	};

	if (token) {
		const session: ISession = getCookieSession();
		options.headers = {
			Authorization: `Basic ${session.token}`,
			Accept: 'application/json',
			'Content-Type': 'application/json',
		};
	}

	const r = await fetch(`/api/${url}`, options);
	let data = await (r.ok ? r.blob() : r.text());
	if (!r.ok) {
		if (r.status === 404) {
			data = '404 No se pudo completar la peticion con el servidor';
		} else if (r.status === 401 && url !== 'auth/login') {
			deleteCookieSession();
			window.location.href = '/';
		} else {
			data = `${r.status} ${data}`;
		}
	}
	return { ok: r.ok, data };
}

export default async function fetchApi(
	url: string,
	body?: any,
	token: boolean = true
) {
	const options: RequestInit = {
		body: JSON.stringify(body),
		method: body ? 'POST' : 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	};

	if (token) {
		const session: ISession = getCookieSession();
		options.headers = {
			Authorization: `Basic ${session.token}`,
			Accept: 'application/json',
			'Content-Type': 'application/json',
		};
	}

	const r = await fetch(`/api/${url}`, options);
	let data = await (r.ok ? r.json() : r.text());
	if (!r.ok) {
		if (r.status === 404) {
			data = '404 No se pudo completar la peticion con el servidor';
		} else if (r.status === 401 && url !== 'auth/login') {
			deleteCookieSession();
			window.location.assign('/');
		} else {
			data = `${r.status} ${data}`;
		}
	}
	return { ok: r.ok, data };
}
