import { Cookies } from 'react-cookie';
import { ISession } from './types';
const cookies = new Cookies();

export const saveCookieSession = (session: ISession) =>
	cookies.set('session', session, { path: '/' });

export const deleteCookieSession = () =>
	cookies.remove('session', { path: '/' });

export const getCookieSession = () => cookies.get('session');
