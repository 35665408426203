import { Component } from 'react';

import {
	Checkbox,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	Select
} from '@material-ui/core';

export interface SelectItem {
	label: string;
	value: string;
}

interface SelectProps {
	value: string[];
	onChange: (e: any) => void;
	items: SelectItem[];
	label: string;
}
interface SelectState { }

export default class MultipleSelect extends Component<
	SelectProps,
	SelectState
> {
	isItemInValue(item: SelectItem): boolean {
		return Boolean(this.props.value.find((v) => v === item.value));
	}

	get isAllSelected(): boolean {
		for (const item of this.props.items) {
			if (!this.props.value.find((v) => item.value === v)) {
				return false;
			}
		}
		return true;
	}

	render() {
		const { value, onChange, items, label } = this.props;
		const isAllSelected = this.isAllSelected;

		return (
			<FormControl fullWidth>
				<InputLabel>{label}</InputLabel>
				<Select
					value={value}
					multiple
					onChange={(e, val) => {
						console.log(e.target.value);
						if ((e.target.value as typeof value).indexOf('') > -1) {
							if (isAllSelected) {
								onChange([]);
							} else {
								onChange(items.map((v) => v.value));
							}
						} else {
							onChange(e.target.value);
						}
					}}
					renderValue={(val) => {
						return (val as typeof value)
							.map(
								(v) =>
									(
										items.find((i) => i.value === v) || {
											label: '',
										}
									).label
							)
							.join(', ');
					}}
				>
					<MenuItem value={''}>
						<Checkbox checked={isAllSelected} />
						<ListItemText
							primary={
								isAllSelected
									? 'Deseleccionar todos'
									: 'Selecionar todos'
							}
						/>
					</MenuItem>
					{items.map((item) => (
						<MenuItem key={item.value} value={item.value}>
							<Checkbox checked={this.isItemInValue(item)} />
							<ListItemText primary={item.label} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		);
	}
}
