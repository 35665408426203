import { getCookieSession, deleteCookieSession } from './cookies';

export default function componentSession(props: any, route: string) {
	const session = getCookieSession();
	const { session: propSession, history, setSession, deleteSession } = props;
	if (session && !propSession) {
		setSession(session);
	} else if (
		(session && session.type !== route) ||
		(propSession && propSession.type !== route)
	) {
		console.log('los permisos no coinciden');
		deleteCookieSession();
		deleteSession();
	} else if (!session && !propSession) {
		console.log('no hay ninguna sesion');
		history.replace('/login');
	}
}
