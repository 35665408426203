import React, { ChangeEvent, FormEvent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
	Typography,
	withStyles,
	Card,
	CardContent,
	Grid,
	TextField,
	CardActions,
	Button,
	IconButton,
	LinearProgress,
} from '@material-ui/core';
import {
	VisibilityOffRounded as EyeOffIcon,
	VisibilityRounded as EyeIcon,
} from '@material-ui/icons';

import { setSession } from '../redux/actions';
import { saveCookieSession } from '../cookies';

import backgronud from '../img/undraw_subscribe.svg';
import styles from '../styles/login';
import { ISession } from '../types';
import { getSession } from '../redux/selectors';
import { RootState } from '../redux/reducers';
import fetchApi from '../fetch';

import '../styles/login.css';

interface IValues {
	usuario: string;
	password: string;
}

interface LoginProps extends RouteComponentProps {
	classes: Record<string, string>;
	setSession: typeof setSession;
	session: ISession | null;
}

interface LoginState {
	showPassword: boolean;
	errorMessage: string;
	loading: boolean;
	values: IValues;
}

class Login extends React.Component<LoginProps, LoginState> {
	constructor(props: LoginProps) {
		super(props);
		this.state = {
			showPassword: false,
			loading: false,
			errorMessage: '',
			values: {
				usuario: '',
				password: '',
			},
		};
	}

	componentDidMount() {
		console.log('mounted login');
	}

	componentDidUpdate() {
		const { session, history } = this.props;
		if (session) {
			history.replace('/' + session.type);
		}
	}

	handleValue(prop: keyof IValues) {
		const { values } = this.state;
		return (e: ChangeEvent) => {
			this.setState({
				values: {
					...values,
					[prop]: (e.target as HTMLInputElement).value,
				},
				errorMessage: '',
			});
		};
	}

	toggleShowPassword() {
		return (e: any) => {
			this.setState({ showPassword: !this.state.showPassword });
		};
	}

	handleSubmit() {
		return async (e: FormEvent) => {
			e.preventDefault();
			const { values, loading } = this.state;
			if (!loading) {
				console.log('submitted');
				this.setState({ loading: true });

				const r = await fetchApi(
					'auth/login',
					{
						username: values.usuario,
						password: values.password,
					},
					false
				);

				if (r.ok) {
					const { token, name, type } = r.data;
					const session: ISession = {
						token,
						name,
						type: type === 1 ? 'user' : 'admin',
					};
					saveCookieSession(session);
					this.props.setSession(session);
				} else {
					this.setState({
						errorMessage: r.data as string,
						loading: false,
					});
				}
			}
		};
	}

	get passwordIcon() {
		const { showPassword } = this.state;
		return showPassword ? (
			<EyeOffIcon fontSize='small' />
		) : (
			<EyeIcon fontSize='small' />
		);
	}

	render() {
		const { classes } = this.props;
		const {
			showPassword,
			errorMessage,
			loading,
			values: { usuario, password },
		} = this.state;
		return (
			<div className={classes.root}>
				<img
					src={backgronud}
					className={classes.image}
					alt='Imagen de bienvenida'
				/>
				<div className={classes.cardLoginContainer}>
					<form onSubmit={this.handleSubmit()}>
						<Card variant='outlined' className={classes.cardLogin}>
							{loading && <LinearProgress color='secondary' />}
							<CardContent className={classes.cardLoginContent}>
								<Grid
									container
									justify='center'
									direction='column'
									spacing={3}
								>
									<Grid item xs={12}>
										<Typography
											variant='h5'
											color='primary'
											gutterBottom
										>
											LetterManager
										</Typography>
										<Typography
											variant='overline'
											color='textSecondary'
										>
											Inicia Sesion
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<TextField
											label='Usuario'
											fullWidth
											value={usuario}
											onChange={this.handleValue(
												'usuario'
											)}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											label='Contraseña'
											fullWidth
											value={password}
											onChange={this.handleValue(
												'password'
											)}
											type={
												showPassword
													? 'text'
													: 'password'
											}
											InputProps={{
												endAdornment: (
													<IconButton
														size='small'
														color='primary'
														onClick={this.toggleShowPassword()}
													>
														{this.passwordIcon}
													</IconButton>
												),
											}}
										/>
									</Grid>
									{errorMessage !== '' && (
										<Grid
											item
											xs={12}
											className={classes.field}
										>
											<Typography
												color='error'
												variant='caption'
											>
												{errorMessage}
											</Typography>
										</Grid>
									)}
								</Grid>
							</CardContent>
							<CardActions>
								<div className={classes.spacing}></div>
								<Button
									variant='contained'
									color='primary'
									type='submit'
									disabled={loading}
								>
									acceder
								</Button>
							</CardActions>
						</Card>
					</form>
				</div>
			</div>
		);
	}
}

export default withRouter(
	withStyles(styles)(
		connect(
			(store: RootState, ownProps) => {
				return { session: getSession(store), ...ownProps };
			},
			{ setSession }
		)(Login)
	)
);
