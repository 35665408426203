import {
	AppBar,
	IconButton,
	Theme,
	Toolbar,
	Typography,
	Zoom,
} from '@material-ui/core';
import withStyles, { Styles } from '@material-ui/core/styles/withStyles';
import {
	MenuOutlined as MenuIcon,
	ExitToAppRounded as LogoutIcon,
} from '@material-ui/icons';

import Tooltip from './tooltip';

import React, { Component } from 'react';

const styles: Styles<Theme, {}> = (theme) => ({
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
});

interface CustomAppBarProps {
	classes: Record<string, string>;
	onLogout: (e: any) => void;
	title: string;
	prominent?: boolean;
}

interface CustomAppBarState {}

class CustomAppBar extends Component<CustomAppBarProps, CustomAppBarState> {
	render() {
		const { classes, onLogout, title } = this.props;

		return (
			<AppBar position='fixed' color='primary' className={classes.appBar}>
				<Toolbar>
					<IconButton className={classes.menuButton} color='inherit'>
						<MenuIcon color='inherit' />
					</IconButton>
					<Typography variant='h6' noWrap className={classes.title}>
						{title}
					</Typography>
					<Tooltip
						title='Cerrar sesión'
						color='primary'
						TransitionComponent={Zoom}
					>
						<IconButton color='inherit' onClick={onLogout}>
							<LogoutIcon />
						</IconButton>
					</Tooltip>
				</Toolbar>
			</AppBar>
		);
	}
}

export default withStyles(styles)(CustomAppBar);
