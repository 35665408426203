import { Theme } from '@material-ui/core';
import { Styles } from '@material-ui/core/styles/withStyles';

const styles: Styles<
	Theme,
	{
		root: string;
		image: string;
		test: string;
	}
> = (theme: Theme) => ({
	root: {
		width: '100vw',
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			justifyContent: 'center',
		},
		position: 'relative',
		overflow: 'hidden',
	},
	image: {
		position: 'absolute',
		height: '100vh',
		right: '-90%',
		zIndex: -1,
		[theme.breakpoints.up('sm')]: {
			right: '-30%',
		},
		[theme.breakpoints.up('lg')]: {
			right: '-10%',
		},
	},
	spacing: {
		flexGrow: 1,
	},
	cardLoginContainer: {
		[theme.breakpoints.up('md')]: {
			width: '100%',
		},
	},
	cardLogin: {
		margin: theme.spacing(3),
		width: '75vw',
		[theme.breakpoints.up('sm')]: {
			width: '50vw',
		},
		[theme.breakpoints.up('md')]: {
			width: 300,
			marginLeft: theme.spacing(10),
		},
	},
	cardLoginContent: {
		padding: theme.spacing(4),
	},
	field: {
		marginBottom: theme.spacing(2),
	},
});

export default styles;
