import { Tooltip, withStyles } from '@material-ui/core';

const LightTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: theme.palette.primary.main,
		boxShadow: theme.shadows[1],
		fontSize: 12,
		fontWeight: 'bold',
	},
}))(Tooltip);

export default LightTooltip;
