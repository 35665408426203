import { createMuiTheme } from "@material-ui/core";
import { indigo, purple } from "@material-ui/core/colors";

export default createMuiTheme({
    palette: {
        primary: {
            main: indigo.A400
        },
        secondary: {
            main: purple.A700
        }
    },
    typography: {
        fontFamily: 'Open Sans',
        fontSize: 12,
    }
})