import {
	Theme,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Typography,
} from '@material-ui/core';
import withStyles, { Styles } from '@material-ui/core/styles/withStyles';
import React, { MouseEvent } from 'react';
import Tooltip from './tooltip';

const styles: Styles<Theme, {}> = (theme) => ({
	root: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	row: {
		'&:hover': {
			backgroundColor: theme.palette.action.hover,
			cursor: 'pointer',
		},
	},
});

export interface ITableHeader {
	name: string;
	field: string;
}

interface TableProps {
	classes: Record<string, string>;
	headers: ITableHeader[];
	items: any[];
	onItemClick?: (e: MouseEvent, item: any) => void;
	tooltip?: string;
}
interface TableState {}

class FullTable extends React.Component<TableProps, TableState> {
	render() {
		const { classes, headers, items, onItemClick, tooltip } = this.props;

		return (
			<TableContainer className={classes.root}>
				<Table>
					<TableHead>
						<TableRow>
							{headers.map((h) => (
								<TableCell key={h.field}>
									<Typography
										variant='subtitle2'
										style={{ fontWeight: 'bold' }}
									>
										{h.name}
									</Typography>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{items.map((i) => {
							let row = (
								<TableRow className={classes.row}>
									{headers.map((h) => (
										<TableCell
											key={h.field + '_' + i._id}
											onClick={(e: MouseEvent) =>
												onItemClick
													? onItemClick(e, i)
													: null
											}
										>
											{i[h.field] ? i[h.field] : ''}
										</TableCell>
									))}
								</TableRow>
							);

							if (tooltip) {
								row = (
									<Tooltip
										key={i._id}
										title={tooltip}
										enterDelay={1000}
									>
										{row}
									</Tooltip>
								);
							} else {
								row = (
									<React.Fragment key={i._id}>
										{row}
									</React.Fragment>
								);
							}

							return row;
						})}
						{items.length === 0 && (
							<TableRow>
								<TableCell colSpan={headers.length}>
									<Typography
										align='center'
										style={{ flexGrow: 1 }}
									>
										No hay datos que mostrar
									</Typography>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		);
	}
}

export default withStyles(styles)(FullTable);
